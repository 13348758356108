<template>
  <div :class="{ empty: required && !value }">
    <label v-if="label" class="form-label">{{ label }}</label>
    <div class="input-wrapper" :class="{ 'has-suffix': suffix, 'has-prefix': prefix }">
      <div v-if="prefix" class="prefix">{{ prefix }}</div>
      <input @input="$emit('update:modelValue', $event.target.value)" ref="input" v-model="value" :min="min" :max="max" :type="type" class="form-control" :step="step" :placeholder="placeholder || label" :disabled="props.disabled">
      <div v-if="suffix" class="suffix">{{ suffix }}</div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "~/stores/AuthStore";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  submit: {
    type: Boolean,
    default: false,
  },
  placeholder: {},
  modelValue: {},
  defaultValue: {},
  label: {},
  disabled: {},
  step: {},
  required: {},
  min: {},
  max: {},
  suffix: {},
  prefix: {},
  ignorePermission: {},
});

const emit = defineEmits([ "update:modelValue" ]);
const type = ref(props.type);
const label = ref(props.label);
const disabled = ref(props.disabled);
const placeholder = ref(props.placeholder);
const input = ref(null);

if (type.value === "number") type.value = "text";

if (props.required) {
  if (label.value) label.value += "*";
  if (placeholder.value) placeholder.value += "*";
}

// Berechtigung überprüfen
const route = useRoute();
const authStore = useAuthStore();
if (route.meta.permission && !props.ignorePermission && !authStore.hasPermission([ route.meta.permission ])) disabled.value = true;

const value = computed({
  get() {
    return props.modelValue || props.defaultValue;
  },
  set(value) {
    if (props.type === "number") value = value.replace(",", ".");
    emit("update:modelValue", value);
  },
});

defineExpose({
  focus: () => {
    input.value.focus();
  },
});
</script>
